
const HOST = window.location.host;
const streamingProto = window.location.protocol === 'http:' ? 'ws' : 'wss';
const STREAMING_LOCATION = `${streamingProto}://${HOST}`;

const INITIAL_META = {
  auto_play_gif: false,
  boost_modal: false,
  delete_modal: true,
  display_sensitive_media: false,
  domain: HOST,
  locale: "en",
  mascot: '',
  max_toot_chars: 65536, // instance
  reduce_motion: false,
  streaming_api_base_url: STREAMING_LOCATION,
  unfollow_modal: false,
}

const stateFrame = () => ({
  meta: {
    ...INITIAL_META,
  },
  accounts: {
  },
  compose: {
  },
  custom_emojis: {
  },
  settings: {
  },
  "media_attachments": {
    "accept_content_types": [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".webm",
      ".mp4",
      ".m4v",
      "image/jpeg",
      "image/png",
      "image/gif",
      "video/webm",
      "video/mp4",
    ],
  },
});

const userProfileIntoState = (state, userData, accessToken) => {
  const userId = userData.id;

  state.meta.access_token = accessToken;
  state.meta.me = userId;
  state.meta.admin = userData.pleroma.is_admin;

  state.accounts[userId] = userData;

  return state;
};

const instanceIntoState = (state, instance) => {
  state.meta.max_toot_chars = instance.max_toot_chars;
  state.char_limit = instance.max_toot_chars;
  state.max_toot_chars = instance.max_toot_chars;
  state.poll_limits = instance.poll_limits;

  state.compose.allow_content_types = instance.pleroma.post_formats;

  return state;
};

const mascotIntoState = (state, { url }) => {
  state.meta.mascot = url;

  return state;
};

export {
  INITIAL_META,
  stateFrame,
  userProfileIntoState,
  instanceIntoState,
  mascotIntoState,
};
